<template>
    <v-container>
        <v-row class="fill-height" align-content="center" justify="center" v-if="loading">
            <v-col class="subtitle-1 text-center" cols="12">Carregando detalhes do seu festival...</v-col>
            <v-col cols="6">
                <v-progress-linear indeterminate rounded height="6"></v-progress-linear>
            </v-col>
        </v-row>
        <Form v-if="festival != null" :item="festival"/>
    </v-container>
    
</template>
<script>
import Form from '../../../../components/dashboard/festival/festival/Form'
import { mapState, mapActions } from 'vuex';
import { HOME } from '../../../../routes';
export default {
    components: {
        Form
    },
    data() {
        return {
           
        }
    },
    created() {
        
        if (this.$user.isGuest) {
            this.$router.push({path: HOME}); //TODO check auth with globar guard
            return;
        }
        this.fetchDetail();
    },
    computed: {
        id() {
            return this.$route.params.id;
        },
        ...mapState('dashboard/myFestival', ['festival', 'loading'])
    },
    methods: {
        ...mapActions('dashboard/myFestival', ['detail']),
        fetchDetail() {
            this.detail({id: this.id, withoutSubscriptions: true, noFile: true});
        }
    },
}
</script>